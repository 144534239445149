<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Incidents') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-incident']" class="column is-narrow">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" :to="{ name: 'incidents-add' }">
              {{ $t('Add Incident') }}
            </router-link>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #property="{ record }">
            {{ record.property ? record.property.abbreviation : '' }}
          </template>
          <template #reportedTime="{ record }">
            {{ $filters.date(record.occurredAt, { format: $variables.datetimeFormat }) }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #insuranceStatus="{ record }">
            {{ $t(record.insuranceStatus) }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Incidents": "Incidents",
"Add Incident": "Add Incident",
"Incident Name": "Incident Name",
"Property": "Property",
"Reported Time": "Reported Time",
"Status": "Status",
"Insurance Status": "Insurance Status",
"covered": "Covered",
"not covered": "Not covered",
},
"ja": {
"Incidents": "重大な設備不備",
"Add Incident": "重大な設備不備を追加",
"Incident Name": "重大な設備不備名",
"Property": "物件",
"Reported Time": "報告時間",
"Status": "ステータス",
"Insurance Status": "保険ステータス",
"covered": "適応済",
"not covered": "未適応",
}
}
</i18n>

<script>
import SearchForm from '@/views/incidents/components/SearchForm';
import list from '@/views/incidents/mixins/list';

export default {
  name: 'Incident',
  components: {
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Incident Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Reported Time'),
          dataIndex: 'reportedTime',
          slots: { customRender: 'reportedTime' },
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Insurance Status'),
          key: 'insuranceStatus',
          dataIndex: 'insuranceStatus',
          slots: { customRender: 'insuranceStatus' },
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'incidents-edit', params: { id: record.id } });
        },
      };
    },
  },
};
</script>
