<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit Incident') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm
          ref="form"
          :value="incident"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
          @image-removed="handleImageRemoved"
        />
        <a-alert v-if="submitted" type="success">
          <template #message>
            <span v-html="$t('incident-updated', { name: incident.name })" />
            <router-link to="/incidents">
              {{ $t('View incidents list') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Edit Incident": "Edit Incident",
"incident-updated": "incident <strong>{name}</strong> updated,",
"View incidents list": "View incidents list"
},
"ja": {
"Edit Incident": "重大な設備不備を編集",
"incident-updated": "重大な設備不備 <strong>{name}</strong> が更新されました。",
"View incidents list": "重大な設備不備リストを閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/incidents/components/EditForm';
import { appendImageUrl, getResponseError } from '@/utils/util';

export default {
  name: 'Edit',
  components: { EditForm },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.name === 'property-incident') {
        vm.fromProperty = true;
      }
    });
  },
  data() {
    return {
      fromProperty: false,
      incident: undefined,
      isSubmitting: false,
      error: '',
      submitted: false,
      removedImage: [],
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'incidents-edit') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('incidents/get', this.$route.params.id)
        .then((resp) => {
          this.incident = {
            id: resp.id,
            name: resp.name,
            description: resp.description,
            propertyId: resp.propertyId,
            occurredAt: resp.occurredAt,
            status: resp.status,
            insuranceStatus: resp.insuranceStatus,
            images: appendImageUrl(resp.images),
          };
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleSubmit(data) {
      try {
        const { id } = this.incident;
        this.isSubmitting = true;
        this.submitted = false;
        this.incident = undefined;
        this.error = '';
        const { images, ...rest } = data;
        const resp = await this.$store.dispatch('incidents/update', { id, ...rest });
        if (images.length) {
          const imgPayload = { id, file: images };
          await this.$store.dispatch('incidents/addImages', imgPayload);
        }
        if (this.removedImage.length) {
          await Promise.all(
            this.removedImage.map(async (img) => {
              if (img.id) {
                await this.$store.dispatch('incidents/deleteImage', { id, imageId: img.id });
              }
            }),
          );
        }
        this.$message.success('Update success');
        this.isSubmitting = false;
        this.incident = {
          id: resp.id,
          name: resp.name,
          description: resp.description,
          propertyId: resp.propertyId,
          occurredAt: resp.occurredAt,
          status: resp.status,
          insuranceStatus: resp.insuranceStatus,
          images: resp.images,
        };
        this.$refs.form.resetFields();
        this.submitted = true;
        // go back to property-incident
        if (this.fromProperty) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'incidents' });
        }
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
    handleImageRemoved(file) {
      this.removedImage.push(file);
    },
  },
};
</script>
