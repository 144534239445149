<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add Incident') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm ref="form" :is-submitting="isSubmitting" :value="form" @submit="handleSubmit" />
        <a-alert v-if="incident" type="success">
          <template #message>
            <span v-html="$t('incident-added', { name: incident.name })" />
            <router-link to="/incidents">
              {{ $t('View incidents list') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Incident": "Add Incident",
"incident-added": "incident <strong>{name}</strong> added,",
"View incidents list": "View incidents list"
},
"ja": {
"Add Incident": "重大な設備不備を追加",
"incident-added": "重大な設備不備 <strong>{name}</strong> が追加されました。",
"View incidents list": "重大な設備不備リストを閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/incidents/components/EditForm';
import { getResponseError } from '@/utils/util';

export default {
  name: 'Add',
  components: { EditForm },
  data() {
    return {
      incident: undefined,
      isSubmitting: false,
      error: '',
      form: {
        propertyId: undefined,
        occurredAt: undefined,
        status: undefined,
        insuranceStatus: undefined,
        images: [],
      },
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'incident-add') {
        this.initData();
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.$route.query.propertyId) {
        this.form.propertyId = this.$route.query.propertyId;
      }
      if (this.$route.query.occurredAt) {
        this.form.occurredAt = this.$route.query.occurredAt;
      }
    },
    async handleSubmit(form) {
      try {
        this.error = '';
        this.isSubmitting = true;
        this.incident = undefined;

        const { images, ...payload } = form;
        const { data } = await this.$store.dispatch('incidents/add', payload);

        if (images.length) {
          const imgPayload = {
            id: data.id,
            file: images,
          };

          await this.$store.dispatch('incidents/addImages', imgPayload);
        }

        this.isSubmitting = false;
        this.incident = data;
        this.$refs.form.resetFields();
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
  },
};
</script>
